<template>
  <div id="app">
    <router-view v-if="$route.path == '/login'||$route.path.includes('company')" />
    <div v-else ref="content" class="main_container">
      <header v-if="!isMobile" class='background'
        :style="{ 'backgroundImage': 'url(' + headerUrl + ')' , 'backgroundSize':'cover' }">
        <div class="header">
          <div class="logo-wrap">
            <div class="title"> {{pageTitle}}</div>
            <div class='navigaterBar'>
              <el-button type='text' :class="{ active: activeButton === 'home' }" class='navigateBtn'
                @click="navigateTo('/', 'home')">首 页</el-button>
              <el-button type='text' :class="{ active: activeButton === 'appMarket' }" class='navigateBtn'
                @click="navigateTo('/appMarket', 'appMarket')">AI应用市场</el-button>
            </div>
          </div>
          <div class="header-right">
            <div v-if="username" class="username">
              <el-dropdown @command="clickDropDown">
                <div class="user-drop">
                  <img class="avatar" src="@/assets/images/home/avatar.png" alt="">
                  <div class="name"> {{ username }}</div>
                  <i class="el-icon-arrow-down el-icon--right" style='font-size:16px'></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="companyInfo">企业信息</el-dropdown-item>
                  <el-dropdown-item command="companyApps">应用管理</el-dropdown-item>
                  <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <el-button v-else class="company_enter" @click="toLogin">厂商入驻</el-button>

          </div>
        </div>
      </header>
      <main>
        <router-view />
      </main>
      <!-- <div class="icon_group">
        <div class="icon_item">
          <img src="@/assets/images/home/chat_icon.png" alt="">
        </div>
        <div class="icon_item">
          <img src="@/assets/images/home/tel_icon.png" alt="">
          <div class="hint">
            <div class="hint_top">联系我们</div>
            <div class="hint_bot">025-8888888</div>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</template>
<script>
/* eslint-disable */
import userService from "@/api/api";
export default {
  watch: {
    '$route.path': {
      handler (val, O) {
        let userInfo = window.localStorage.getItem('ecolUserInfo')
        this.userInfo = JSON.parse(userInfo)
        this.username = userInfo ? JSON.parse(userInfo).username : '';

      }
    }
  },
  data () {
    return {
      contentHeightExceedsVh: false,
      reqDesc: '',
      name: '',
      phone: '',
      applyVisible: false,
      dropdownMenu: [
        {
          label: '算力服务',
          command: '/mainbusines'
        },
        {
          label: 'Al周边产品',
          command: '/peripherals'
        },
      ],
      username: '',
      logoUrl: require('@/assets/images/logo.png'),
      userInfo: {},
      headerUrl: require('@/assets/images/home/headerUrl.png'),
      activeButton: '',
      isMobile: false
    };
  },
  computed: {
    pageTitle () {
      return this.$store.state.config.top || '南京智能计算中心生态应用平台';
    }
  },
  created () {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    let a = document.getElementById('app')
    if (isAndroid) {
      a.style = "min-width: 800px;height: 100%;"
    } else {
      a.style = ""
    }
    let userInfo = window.localStorage.getItem('ecolUserInfo')
    this.username = userInfo ? JSON.parse(userInfo).username : '';
    this.isMobile = /Android|iPhone|iPad|iPod|Mobile/i.test(navigator.userAgent);
    this.fetchAppConfig();
  },

  methods: {

    navigateTo (route, buttonKey) {
      this.activeButton = buttonKey;
      this.$router.push(route)
    },
    handleGo (val) {
      if (val != this.$route.path) {
        this.$router.push(val)
      }
    },
    close () {
      this.showSolution = false
    },
    handleClick (val) {
      if (val.name != this.$route.path) {
        this.$router.push(val.name)
      }
    },
    toRoute (val) {
      this.$router.push(val)
    },
    toLogin () {
      let from = this.$route.path
      this.$router.push({
        path: '/login',
        query: {

        }
      })
    },
    goCompanyInfo () {
      this.$router.push('/company/manage')
    },
    goCompanyApp () {
      this.$router.push('/company/apps')
    },
    goManagement () {
      this.$router.push('/companyManagement')
    },
    logout () {
      window.localStorage.removeItem('ecolUserInfo')
      this.$router.push({
        path: '/'
      });
      window.location.reload()
    },
    clickDropDown (command) {
      switch (command) {
        case 'logout':
          this.logout();
          break;
        case 'companyInfo':
          this.goCompanyInfo()
          break;
        case 'companyApps':
          this.goCompanyApp()
          break;
        case 'companyManagement':
          this.goManagement()
          break;
        default:
          break;
      }
    },
    async fetchAppConfig () {
      let result = await userService.getAppConfig();
      if (result.data) {
        this.$store.commit('setConfig', result.data);
        const iconUrl = `/config/ecol/app/image?path=${this.$store.state.config.icon}`
        document.querySelector('link[rel="icon"]').setAttribute('href', iconUrl);
        document.title = this.$store.state.config.top;
      }
    }
  }
}

</script>

<style lang="scss">
#app,
html,
body {
  width: 1920px;
  overflow-x: hidden;
  background: #f5f5f5;
  /* min-height: 1080px; */
  box-sizing: border-box;
  position: relative;
}
#app {
  margin-top: 0px;
}
@font-face {
  font-family: "PangMenZhengDao";
  src: url("~@/assets/text/PangMenZhengDaoBiaoTiTi-1.ttf");
}
.common-btn {
  background: #1144ab !important;
  border: none !important;
  border-radius: 0px !important;
  height: 40px;
}
.main_container {
  position: relative;
  width: 100%;
  height: 100%;
  header {
    box-sizing: border-box;
    width: 1920px;
    height: 59.33px;
    .header {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 23px;
      .logo-wrap {
        display: flex;
        align-items: center;
        .title {
          font-size: 32px;
          font-family: PangMenZhengDao;
          font-weight: normal;
          text-align: justify;
          color: #333333;
          line-height: 36px;
          letter-spacing: 1.6px;
        }
        .navigaterBar {
          margin-left: 98px;
          display: flex;
          .navigateBtn {
            position: relative;
            font-size: 18.67px;
            font-weight: bold;
            text-align: justify;
            margin-right: 20px;
            color: #333333;
          }
          .active {
            color: #0061e3;
          }
        }
      }
      .header-right {
        display: flex;
        align-items: center;
        .user-drop {
          display: flex;
          align-items: center;
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .name {
            margin-left: 11px;
          }
        }
        .company_enter {
          display: flex;
          align-items: center !important;
        }
      }
    }
  }

  .icon_group {
    position: absolute;
    right: 10px;
    bottom: 80px;
    .icon_item {
      position: relative;
      &:hover .hint {
        opacity: 1;
      }
      img {
        width: 80px;
        height: 80px;
      }
      .hint {
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
        position: absolute;
        right: 80px;
        top: 0px;
        width: 173.33px;
        height: 80px;
        background: #ffffff;
        box-shadow: 0px 12px 37.33px 10.67px rgba(0, 0, 0, 0.05),
          0px 8px 21.33px 0px rgba(0, 0, 0, 0.08),
          0px 4px 8px -5.33px rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        padding: 12px 20px;
        border-radius: 15px;
        &::after {
          content: "";
          position: absolute;
          right: -9px;
          top: 50%;
          border-left: 10px solid #ffffff;
          transform: translateY(-50%);
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
        }
        .hint_top {
          font-size: 18.67px;
          font-family: SourceHanSansSC, SourceHanSansSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #000000;
          line-height: 29.33px;
        }
        .hint_bot {
          font-size: 18.67px;
          font-family: SourceHanSansSC, SourceHanSansSC-Bold;
          font-weight: 700;
          text-align: left;
          color: #2774f7;
          line-height: 29.33px;
        }
      }
    }
  }
}

.zZindex {
  z-index: 99999999999 !important;
}

.el-image-viewer__canvas {
  img {
    max-width: 92vw !important;
    max-height: 92vh !important;
  }
}
.md-editor-preview-wrapper {
  padding: 0px !important;
  background: #fff !important;
  font-size: 14px !important;
  p {
    font-size: 14px !important;
    font-family: "siyuan" !important;
    color: #606266 !important;
  }
}
.md-editor-preview {
  font-size: 14px !important;
}
blockquote {
  background: #f6f6f8 !important;
  margin-top: 0px !important;
  font-size: 14px !important;
}
blockquote:empty {
  display: none;
}
/* 设置滚动条宽度 */
::-webkit-scrollbar {
  width: 6px;
}

/* 设置滚动条轨道样式 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1fa; /* 轨道背景颜色 */
  border-radius: 10px; /* 轨道圆角 */
}

/* 设置滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #c4c2c2; /* 滑块背景颜色 */
  border-radius: 10px; /* 滑块圆角 */
}

/* 设置滑块 hover 时的样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #a3a3a3; /* hover 时背景颜色 */
}

.md-editor div.default-theme {
  --md-theme-quote-border: 3px solid #7388f0;
}
</style>
